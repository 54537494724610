<template>
  <div>
    <!-- Текст "Зарабатывай с Перекрёстком" -->
    <div class="header-text">
      <p>Зарабатывай</p>
    </div>

    <div class="header-text2">
      <p>с Перекрёстком!</p>
    </div>

    <div class="vacancy-sections">
      <!-- Работа в супермаркете -->
      <router-link
        :to="{
          name: 'vacancy-lend-detail',
          params: { type: 'market' },
          query: query,
        }"
        class="vacancy-item large"
      >
        <div
          class="vacancy-box"
          style="background-color: #eb5b49; width: 400px; height: 300px"
        >
          <div class="vacancy-title" style="padding-left: 10px">
            Работа <br />
            в супермаркете
          </div>
          <img
            src="/market.svg"
            alt="Работа в супермаркете"
            class="icon"
            style="
              width: 300px;
              height: 230px;
              position: absolute;
              top: 20px;
              left: 100px;
            "
          />
          <button class="vacancy-btn" style="margin-top: 180px; width: 150px">
            откликнуться
          </button>
          <div class="dots"></div>
        </div>
      </router-link>

      <!-- Работа в распределительном центре и Управление супермаркетом -->
      <div class="vacancy-column">
        <!-- Работа в распределительном центре -->
        <router-link
          :to="{
            name: 'vacancy-lend-detail',
            params: { type: 'stock' },
            query: query,
          }"
          class="vacancy-item medium"
        >
          <div
            class="vacancy-box"
            style="
              background-color: #55c350;
              width: 320px;
              height: 150px;
              margin-bottom: 15px;
            "
          >
            <div class="vacancy-title" style="padding-left: 10px">
              Работа <br />
              в распределительном <br />
              центре
            </div>
            <div class="vacancy-content">
              <button
                class="vacancy-btn"
                style="width: 150px; margin-top: 18px"
              >
                откликнуться
              </button>
              <img
                src="/corzina.svg"
                alt="Работа в распределительном центре"
                class="icon-small"
                style="
                  position: absolute;
                  top: 60px;
                  right: 20px;
                  width: 90px;
                  height: 90px;
                "
              />
            </div>
            <div class="dots"></div>
          </div>
        </router-link>

        <!-- Управление супермаркетом -->
        <a
          href="/director"
          class="vacancy-item medium"
        >
          <div
            class="vacancy-box"
            style="background-color: #ad2c4c; width: 320px; height: 130px"
          >
            <div class="vacancy-title" style="padding-left: 10px">
              Управление <br />
              супермаркетом
            </div>
            <div class="vacancy-content">
              <button
                class="vacancy-btn"
                style="width: 150px; margin-top: 10px"
              >
                откликнуться
              </button>
              <img
                src="/admin.svg"
                alt="Управление супермаркетом"
                class="icon-small"
                style="
                  position: absolute;
                  top: 40px;
                  right: 20px;
                  width: 90px;
                  height: 90px;
                "
              />
            </div>
            <div class="dots"></div>
          </div>
        </a>
      </div>

      <!-- Работа в кафе и Работа в доставке -->
      <div class="vacancy-column">
        <!-- Работа в кафе -->
        <a
          href="https://himki.hh.ru/employer/1942336?hhtmFrom=vacancy"
          class="vacancy-item small"
        >
          <div
            class="vacancy-box"
            style="
              background-color: #6d6abf;
              width: 160px;
              height: 150px;
              margin-bottom: 15px;
            "
          >
            <div
              class="vacancy-title"
              style="padding-left: 10px; margin-top: 10px"
            >
              Работа <br />
              в кафе
            </div>
            <div class="dots"></div>
            <img
              src="/cup.svg"
              alt="Работа в кафе"
              class="icon-small"
              style="
                position: absolute;
                top: 20px;
                right: 10px;
                width: 80px;
                height: 80px;
              "
            />
            <button class="vacancy-btn" style="margin-top: 30px; width: 150px">
              откликнуться
            </button>
          </div>
        </a>

        <!-- Работа в доставке -->
        <router-link
          :to="{
            name: 'vacancy-lend-detail',
            params: { type: 'delivery' },
            query: query,
          }"
          class="vacancy-item small"
        >
          <div
            class="vacancy-box"
            style="background-color: #005a28; width: 160px; height: 130px"
          >
            <div
              class="vacancy-title"
              style="padding-left: 10px; margin-top: 10px"
            >
              Работа <br />
              в доставке
            </div>
            <button class="vacancy-btn" style="width: 150px">
              откликнуться
            </button>
            <div class="dots"></div>
          </div>
        </router-link>
      </div>
    </div>

    <div class="banner">
      <a href="/vacancy/market?=undefined">
        <img src="/poluchka.png" alt="Баннер" />
      </a>
    </div>
    <div class="form-container">
      <div class="dots"></div>
      <div class="steps-section">
        <h2>3 простых шага – подберём работу по душе!</h2>

        <div class="step-box">
          <div class="step-content">
            <img
              src="/planshet.svg"
              alt="Иконка план"
            />
            <p>Выбирай вакансию<br />и заполняй анкету</p>
          </div>
          <div class="dotsGreen"></div>
        </div>

        <div class="step-box">
          <div class="step-content">
            <img
              src="/phone.svg"
              alt="Иконка телефон"
            />
            <p>Дождись звонка<br />от менеджера<br />для уточнения деталей</p>
          </div>
          <div class="dotsGreen"></div>
        </div>

        <div class="step-box">
          <div class="step-content">
            <img
              src="/mess.svg"
              alt="Иконка сообщение"
            />
            <p>Пройди<br />собеседование</p>
          </div>
          <div class="dotsGreen"></div>
        </div>
      </div>

      <modal :adaptive="true" name="modal"><div class="thanks-modal flex"><span>Ваш отклик отправлен!</span></div></modal>

      <div class="form-section">
        <h2>Заполняй анкету</h2>
        <p class="subtitle">и присоединяйся к команде "Перекрёстка"</p>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="fio">Полное имя</label>
            <span class="form__error-txt __relative" v-show="errors.has('fio')">{{ errors.first('fio') }}</span>
            <input
              type="text"
              id="fio"
              name="fio"
              placeholder="Фамилия, Имя, Отчество"
              v-model="fio"
              v-validate="'required|nameRu|fullNameRu'"
              data-vv-validate-on="blur"
              required
            />
          </div>

          <div class="form-group">
            <label for="phone">Телефон</label>
            <span class="form__error-txt __relative" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
            <input
              type="tel"
              id="phone"
              placeholder="Введи телефон"
              v-model="phone"
              v-validate="'required|tel'"
              v-mask="'+7(###) ###-##-##'"
              required
            />
          </div>

          <div class="form-group">
            <label for="nationality">Гражданство</label>
            <select v-model="nationality">
              <option value="Российская Федерация">Российская Федерация</option>
              <option value="Беларусь">Беларусь</option>
              <option value="Украина">Украина</option>
              <option value="Казахстан">Казахстан</option>
              <option value="Киргизия">Киргизия</option>
              <option value="Узбекистан">Узбекистан</option>
              <option value="Другое">Другое</option>
            </select>
          </div>

          <div class="form-group">
            <label for="city">Населенный пункт</label>
            <span class="form__error-txt" v-show="errors.has('city')">{{ errors.first('city') }}</span>
            <input
              type="text"
              id="city"
              name="city"
              placeholder="Введи населенный пункт"
              v-model="city"
              v-validate="'required'"
              required
            />
          </div>

          <div class="form-group">
            <label for="careerObjective">Желаемая должность</label>
            <span class="form__error-txt __relative" v-show="errors.has('careerObjective')">{{ errors.first('careerObjective') }}</span>
            <select v-model="careerObjective" name="careerObjective" v-validate="'required'" required>
              <option v-for="vacancy in getMainVacancies()" :value="vacancy.value">
                {{vacancy.title}}
              </option>
            </select>
          </div>

          <!-- Чекбокс с текстом -->
          <div class="form-group checkbox-group">
            <input type="checkbox" id="terms" v-model="agreed" required />
            <label for="terms" class="terms-text">
              Я соглашаюсь и принимаю
              <a href="/pdf/pdn.pdf" target="_blank"> Условия Соглашения </a>об
              оказании услуг по содействию в трудоустройствеи иных видах
              занятости, а также даю
              <a href="/pdf/soglasie-pd.docx" target="_blank"
                >Согласие на обработку персональных данных</a
              >
            </label>
          </div>

          <button type="submit" class="submit-btn" :disabled="disabled">
            <span>Откликнуться</span>
            <div class="loader" :class="{loader_active: !!disabled}" />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {getMainVacancies, isDeliveryVacancy, isDriverVacancy} from '@/model/vacancies';
import urlJoin from 'url-join';
import {getSourceFromCompany} from '@/model/company2Source';
export default {
  data() {
    return {
      fio: "",
      phone: "",
      nationality: "Российская Федерация",
      city: "",
      careerObjective: "",
      agreed: false,
      params: {},
      disabled: false,
    };
  },
  created() {
    this.params = this.$route.query;
  },
  methods: {
    getMainVacancies,
    handleSubmit() {
      if (!this.agreed) {
        alert("Вы должны согласиться с условиями для отправки формы.");
        return;
      }

      this.$validator.validateAll().then((result) => {
        if (!result) {
          return
        }
        this.disabled = true;

        // Вызов метрики Yandex Metrika
        ym(92372732, "reachGoal", "lead", {
          pageurl: this.$route.query.page,
          nat: this.nationality,
          loc: this.city,
          vac: this.careerObjective.replace(/\&nbsp\;/gi, " "),
        });

        // Вызов метрики Top.Mail.Ru
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({ type: "reachGoal", id: 3421596, goal: "Lead" });

        const onSendSuccess = () => {
          this.disabled = false;
          this.fio = '';
          this.phone = '';
          this.city = '';
          this.careerObjective = '';
          this.$validator.reset();
          this.agreed = false;
          this.$modal.show('modal');
        }

        const onSendFail = () => {
          this.disabled = false;
          alert('Ошибка при отправке формы. Попробуйте позже.');
        }

        const sendNotificationMail = (recipients) => {
          const params = {};
          params['fio'] = this.fio;
          params['phone'] = this.phone;
          params['nationality'] = this.nationality;
          params['city_name'] = this.city;
          params['career_objective'] = this.careerObjective;
          params['region_name'] = this.city;
          params['vacancy_name'] = 'main';
          params['want_type'] = 'main';
          params['want_position'] = 'main';
          params['utm_source'] = this.params.utm_source || '';
          params['utm_medium'] = this.params.utm_medium || '';
          params['utm_campaign'] = this.params.utm_campaign || '';
          this.disabled = true;
          axios.post(urlJoin(process.env.VUE_APP_FEEDBACK_URL, '/notify/perekrestok/application', `?recipients=${recipients}`), params).then(onSendSuccess).catch(onSendFail)
        }

        const createSkillazCandidate = () => {
          const params = new FormData()
          params.append('fullName', this.fio);
          params.append('vacancyId', this.careerObjective);
          params.append('city', this.city);
          params.append('phone', this.phone.replace(/[-()\s]/g, ''));
          params.append('nationality', this.nationality);
          params.append('skillazType', 'perekrestok');
          const source = getSourceFromCompany(this.params.utm_campaign);
          if (source) {
            params.append('skillazId', source);
          }

          this.disabled = true;
          axios.post(urlJoin(process.env.VUE_APP_FEEDBACK_URL, '/candidate'), params).then(onSendSuccess).catch(onSendFail)
        }

        if (isDeliveryVacancy(this.careerObjective)) {
          if (isDriverVacancy(this.careerObjective)) {
            sendNotificationMail('driver');
          } else {
            sendNotificationMail('courier');
          }
        } else {
          createSkillazCandidate();
        }
      });
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Gilroy:wght@700&display=swap");

.thanks-modal {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 50px;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.form__error-txt {
  position: absolute;
  color: var(--cl-red);
  font-size: 12px;
  bottom: 0;
  left: 0;
}

.form-container .dots {
  position: absolute;
  top: 10px;
  right: 20px;
}

* {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
}

.header-bar {
  background-color: #55c350;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 200px;
}
.banner {
  width: 100%;
  max-width: 900px; /* Ограничение ширины блока form-container */
  max-height: 200px; /* Ограничение высоты баннера */
  margin: 20px auto 30px; /* Отступ сверху и снизу */
  box-sizing: border-box;
}

.banner img {
  width: 100%; /* Адаптивная ширина изображения */
  height: 100%; /* Адаптивная высота изображения */
  object-fit: cover; /* Сохранение пропорций, обрезка по краям */
  border-radius: 10px; /* Радиус для округления краев */
}
.header-logo {
  width: 250px;
  margin: 0px 270px;
}

.header-text,
.header-text2 {
  text-align: left;
  font-size: 3em;
  color: #000;
  width: 100%; /* Увеличиваем ширину блока до 80% от ширины экрана */
  max-width: 900px; /* Ограничение максимальной ширины */
  margin: 20px auto; /* Центрируем элемент по горизонтали */
}

.header-text2 {
  padding-left: 60px;
}

/* Вакансии */
.vacancy-sections {
  display: grid;
  grid-template-columns: 400px 320px 160px;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}

.vacancy-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vacancy-box {
  position: relative;
  padding: 0px 0px;
  border-radius: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.vacancy-title {
  font-size: 1.2em;
  color: white;
  margin-bottom: 10px;
  padding-left: 10px;
  text-decoration: none;
  padding: 6px;
}

.icon {
  margin: 10px auto;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 20px;
  left: 100px;
}

.icon-small {
  position: absolute;
  top: 40px;
  right: 20px;
  width: 50px;
  height: 50px;
}

.vacancy-btn {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 0px;
  border-radius: 0 15px 15px 0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3em;
  line-height: 1;
}

a {
  text-decoration: none;
}

.vacancy-btn:hover {
  background-color: #ddd;
}

.vacancy-btn:active {
  background-color: #bbb;
}

.form-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #55c350;
  border-radius: 20px;
  width: 900px;
  margin: auto;
  position: relative;
  margin-top: 20px;
}

.steps-section {
  flex: 1;
  padding: 20px;
  padding-top: 40px;
}

.steps-section h2 {
  color: white;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.step-box {
  background-color: white;
  padding: 35px;
  border-radius: 20px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.step-content {
  display: flex;
  align-items: center;
}

.step-content img {
  width: 30%;
  margin-right: 40px;
}

.step-content p {
  font-size: 1.2em;
  color: black;
}

.dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dots::before,
.dots::after {
  content: "";
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.dotsGreen {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dotsGreen::before,
.dotsGreen::after {
  content: "";
  display: inline-block;
  background-color: #55c350;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.form-section {
  flex: 1;
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  border: 3px solid black;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.form-section h2 {
  font-size: 1.5em;
  color: white;
  margin-left: 30px;
  margin-top: 10px;
}

.form-section .subtitle {
  font-size: 0.8em;
  color: white;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-top: -18px;
  font-weight: normal;
}

.form-group {
  margin-bottom: 10px;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 1em;
  color: white;
  margin-bottom: 5px;
  margin-left: 30px;
  font-weight: normal;
}

.form-group input,
.form-group select {
  width: 80%;
  padding: 10px;
  border: 1px solid #2bb24c;
  border-radius: 10px;
  color: black;
  margin-left: 30px;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: white;
  color: black;
  border: 1px solid #2bb24c;
  border-radius: 40px;
  font-size: 1.2em;
  cursor: pointer;
}

/* Стили для чекбокса */
.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-right: 0px;
  cursor: pointer;
  position: relative;
}

.checkbox-group label {
  color: white;
  cursor: pointer;
}

.checkbox-group a {
  color: white;
  text-decoration: none;
}
.terms-text {
  font-size: 12px !important; /* Принудительное изменение размера текста */
  color: white;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-top: -10px;
  font-weight: normal;
}
.intro-section {
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin-top: 20px;
  position: relative;
  text-align: left;
  width: 900px; /* Устанавливаем такую же ширину, как у других блоков */
  margin: 50px auto; /* Центрируем блок */
}

.intro-section .dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.intro-section p {
  font-size: 1em;
  font-family: "Gilroy", sans-serif;
  font-weight: normal;
}

.benefits-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 900px; /* Устанавливаем такую же ширину, как у других блоков */
  margin: 70px auto; /* Центрируем блок */
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1em;
  width: 100px;
}

.benefit-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.benefit-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  .header-bar {
    margin: 0 10px;
    padding: 10px;
  }

  .header-logo {
    width: 150px;
    margin: 0 auto;
  }

  .header-text,
  .header-text2 {
    font-size: 1.5em;
    margin: 10px auto;
    text-align: center;
  }

  /* Адаптация под мобильные устройства */
  @media (max-width: 768px) {
    .header-bar {
      margin: 0 10px;
      padding: 10px;
    }

    .header-logo {
      width: 150px;
      margin: 0 auto;
    }

    .header-text,
    .header-text2 {
      font-size: 1.5em;
      margin: 10px auto;
      text-align: center;
    }

    /* Уменьшаем intro-section на мобильных */
    .intro-section {
      width: 95% !important;
      padding: 10px !important;
      font-size: 0.9em !important;
      margin: 10px auto !important;
      box-sizing: border-box !important;
    }

    .intro-section p {
      font-size: 1em !important;
      margin: 5px 0 !important;
    }

    /* Адаптация вакансий для мобильной версии */
    .vacancy-sections {
      display: flex !important;
      flex-direction: column !important;
      gap: 10px !important;
      width: 100% !important;
      padding: 0 !important;
      box-sizing: border-box !important;
      align-items: center !important;
    }

    .vacancy-column {
      width: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      gap: 10px !important;
    }

    /* Фиксированная ширина и высота для всех .vacancy-box, убираем изображения */
    .vacancy-box {
      width: 320px !important;
      height: 130px !important;
      padding: 15px !important;
      border-radius: 10px !important;
      box-sizing: border-box !important;
      text-align: center !important;
      margin: 0 auto !important;
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important; /* Кнопка будет всегда внизу */
    }

    /* Убираем изображения */
    .vacancy-box .icon,
    .vacancy-box .icon-small {
      display: none !important;
    }

    .vacancy-title {
      font-size: 1em !important;
      padding-left: 10px !important;
      margin-bottom: auto !important; /* Заголовок будет сверху */
    }

    /* Унифицированные стили для кнопки "откликнуться" */
    .vacancy-btn {
      width: 90% !important;
      margin: 0 auto 10px auto !important;
      padding: 10px 0px !important; /* Горизонтальный padding уменьшен до 0 */
      background-color: white !important;
      color: black !important;
      font-weight: bold !important;
      border: 1px solid #2bb24c !important;
      border-radius: 8px !important;
      cursor: pointer !important;
      font-size: 1.3em !important; /* Увеличенный размер текста */
      line-height: 1 !important; /* Сброс line-height */
    }

    .vacancy-btn:hover {
      background-color: #ddd !important;
    }

    .vacancy-btn:active {
      background-color: #bbb !important;
    }

    /* Адаптация формы и шагов */
    .form-container {
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      padding: 0 10px !important;
      box-sizing: border-box !important;
    }

    /* Перенос form-section под steps-section */
    .steps-section,
    .form-section {
      width: 100% !important;
      margin: 10px auto !important;
      padding: 15px !important;
      box-sizing: border-box !important;
    }

    .steps-section h2,
    .form-section h2 {
      font-size: 1.5em !important;
      text-align: center !important;
    }

    .step-box {
      flex-direction: column !important;
      align-items: center !important;
    }

    .step-content img {
      width: 40px !important;
      height: 40px !important;
    }

    .step-content p {
      font-size: 1em !important;
      text-align: center !important;
    }

    /* Стили для блока преимуществ */
    .benefits-icons {
      flex-direction: column !important;
      align-items: center !important;
      width: 100% !important;
      padding: 0 10px !important;
      box-sizing: border-box !important;
    }

    .benefit-item {
      width: 100% !important;
      margin-bottom: 15px !important;
      text-align: center !important;
    }

    .benefit-item img {
      width: 40px !important;
      height: 40px !important;
      margin-bottom: 5px !important;
    }
  }
}
</style>
