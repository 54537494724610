import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/scripts/validation'
import VueTheMask from 'vue-the-mask'
import VModal from 'vue-js-modal'

if (!process.env.VUE_APP_FEEDBACK_URL) {
  console.error('VUE_APP_FEEDBACK_URL was not set');
}

Vue.use(VModal)

Vue.use(VueTheMask)

Vue.config.productionTip = false

export const bus = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
