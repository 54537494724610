const testWhVacancies = [
  {value: "655480b28f056e8e81c07a1d", title: 'Тестовая вакансия в РЦ'},
];

const testStoreVacancies = [
  {value: "624310640e290d1f77b98678", title: 'Тестовая вакансия в магазин'},
];

const testCourierVacancies = [
  {value: "Тестовая вакансия курьера 1", title: 'Тестовая вакансия курьера 1'},
  {value: "Тестовая вакансия курьера 2", title: 'Тестовая вакансия курьера 2'},
]

const testDriverVacancies = [
  {value: "Тестовая вакансия водитель", title: 'Тестовая вакансия водитель'},
]

const testDirectorVacancies = [
  {value: "62fcb0b1a90daeedbe14e4a3", title: 'Тестовая вакансия директора'},
]

export const getMainVacancies = () => {
  return [
      ...getWarehouseVacancies(),
      ...getStoreVacancies(),
      ...getDeliveryVacancies(),
  ];
}

export const getStoreVacancies = () => {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return [...testStoreVacancies, ...testDirectorVacancies];
  }

  return [
    {title: "Бариста", value: '635fd97109754a0f359f03ab'},
    {title: "Кавист", value: '620f0dfaaa52575c43e24b2a'},
    {title: "Кассир", value: '651d1080a044c1b7ad9c9dd2'},
    {title: "Менеджер кафе", value: '66fe883e2b1e55b94ddaad8a'},
    {title: "Мерчендайзер", value: '662217239d194186584a18a9'},
    {title: "Повар/пекарь", value: '63c254495669cb38a01da6a5'},
    {title: "Продавец", value: '618a16a7365788300954d55d'},
    {title: "Приемщик товаров/товаровед", value: '6471b68eb64799a39aa7b2bc'},
    {title: "Руководитель пекарни/собственного производства", value: '642ac76142702063f56c6db7'},
    ...getStoreDirectorVacancies()
  ]
}

export const getStoreDirectorVacancies = () => {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return testDirectorVacancies;
  }

  return [
    {title: "Директор супермаркета", value: '60499f16cf6f0a6a91e06d9c'},
    {title: "Заместитель директора супермаркета", value: '6205d4218874e3a3b85854b8'},
  ]
}


export const getWarehouseVacancies = () => {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return testWhVacancies;
  }

  return [
    {title: "Водитель электроштабелера", value: '64c27b442476aa52176ab6b2'},
    {title: "Оператор склада", value: '66039b3db9155c843c3e111c'},
  ]
}

const getCourierVacancies = () => {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return testCourierVacancies;
  }

  return [
    {value: "Автокурьер", title: 'Автокурьер'},
    {value: "Велокурьер", title: 'Велокурьер'},
    {value: "Пеший курьер", title: 'Пеший курьер'},
  ]
}

const getDriverVacancies = () => {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return testDriverVacancies;
  }

  return [
    {value: "Водитель кат. С, СЕ", title: 'Водитель кат. С, СЕ'},
  ]
}

export const getDeliveryVacancies = () => {
  return [
    ...getCourierVacancies(),
    ...getDriverVacancies(),
  ]
}

const deliveryValues = new Set([
  ...getCourierVacancies().map(v => v.value),
  ...getDriverVacancies().map(v => v.value),
]);

export const isDeliveryVacancy = (value) => {
  return deliveryValues.has(value);
}

const driverValues = new Set(getDriverVacancies().map(v => v.value))
export const isDriverVacancy = (value) => {
  return driverValues.has(value);
}
