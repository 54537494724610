const map = new Map([
  ['poluchka_msk_ug_sever', 150],
  ['poluchka_barista', 150],
  ['poluchka_msk_brand', 150],
  ['poluchka_rf_vostok', 150],
  ['poluchka_centr_south', 150],
  ['poluchka_rf_volga', 150],
  ['poluchka_spb_lo', 150],
  ['poluchka_spb_brand', 150],
  ['poluchka_rf_south', 150],
  ['poluchka_rf_brand_south_rostov', 150],
  ['poluchka_centr_sever', 150],
  ['poluchka_rf_brand_volga_samara', 150],
  ['poluchka_rf_brand_south_krasnodar', 150],
  ['poluchka_murmansk_brand', 150],
  ['poluchka_petrozavodsk_brand', 150],
  ['poluchka_rf_brand_vostok_ekb', 150],
  ['poluchka_rf_chelni', 150],
  ['poluchka_rf_brand_volga_nn', 150],
  ['poluchka_pskov_brand', 150],
  ['poluchka_rf_brand_south_sochi', 150],
  ['poluchka_rf_brand_volga_kazan', 150],
  ['poluchka_lenobl_brand', 150],
  ['poluchka_rf_brand_south_lipetsk', 150],
  ['poluchka_rf_brand_vostok_surgut', 150],
  ['poluchka_rf_brand_volga_ijevsk', 150],
  ['poluchka_rf_brand_volga_toliatti', 150],
  ['poluchka_rf_brand_vostok_perm', 150],
  ['poluchka_rf_brand_volga_perm', 150],
  ['poluchka_tg', 150],
  ['poluchka_barista_manager_cafe_spb', 150],
  ['poluchka', 150],


  ['merch_msk_ug_sever', 140],
  ['merch_centr_sever', 140],
  ['merch_centr_ug', 140],
  ['merch_spb_lo', 140],
  ['spb_lo', 140],
  ['spb_brand', 140],
  ['sochi_rf', 140],
  ['school', 140],
  ['sborshik', 140],
  ['rf_vostok', 140],
  ['rf_volga', 140],
  ['rf_south', 140],
  ['rf_brand_vostok_ekb', 140],
  ['rf_brand_volga_nn', 140],
  ['rf_brand_volga_kazan', 140],
  ['rf_brand_volga_chelni', 140],
  ['rf_brand_south_voronej', 140],
  ['rf_brand_south_sochi', 140],
  ['retarget', 140],
  ['pskov_brand', 140],
  ['nn_barista', 140],
  ['msk_ug_sever', 140],
  ['msk_brand', 140],
  ['lenobl_brand', 140],
  ['kladovshik', 140],
  ['kavist', 140],
  ['centr_south', 140],
  ['centr_sever', 140],
  ['cash', 140],
  ['barista', 140],
  ['all', 140],
])

const devMap = new Map([
  ['poluchka_barista', 70],
  ['poluchka_msk_brand', 72],
  ['poluchka_rf_vostok', 73],
  ['poluchka_centr_south', 74],
])

export function getSourceFromCompany(company) {
  if (process.env.VUE_APP_TEST_VACANCIES) {
    return devMap.get(company);
  }
  return undefined;//map.get(company);
}
