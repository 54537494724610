<template>
  <div>
    <!-- Основной блок с текстом и белыми точками -->
    <div class="intro-section">
      <div class="dots"></div>
      <p>Зарабатывай в команде с «Перекрёстком»!</p>
      <p>
        Выбирай из широкого спектра вакансий в супермаркетах, распределительных
        центрах, доставке и кафе.
      </p>
      <p>
        Подберём график, который подходит именно тебе, и позаботимся об обучении
        и достойной оплате.
      </p>
    </div>

    <!-- Блок с иконками преимуществ -->
    <div class="benefits-icons">
      <div class="benefit-item">
        <img
          src="/takedollarincash.svg"
          alt="Иконка регулярные выплаты"
        />
        <p>Регулярные <br />выплаты</p>
      </div>
      <div class="benefit-item">
        <img
          src="/location.svg"
          alt="Иконка удобного места работы"
        />
        <p>Удобное место <br />и график работы</p>
      </div>
      <div class="benefit-item">
        <img
          src="/Lider_table.svg"
          alt="Иконка обучения"
        />
        <p>
          Профессиональное <br />обучение <br />
          и карьерное развитие
        </p>
      </div>
      <div class="benefit-item">
        <img
          src="/gift_card.svg"
          alt="Иконка премий"
        />
        <p>
          Ежемесячные премии <br />
          и компенсация питания
        </p>
      </div>
      <div class="benefit-item">
        <img
          src="/salesandbonuses.svg"
          alt="Иконка скидок"
        />
        <p>Корпоративные <br />скидки и бонусы</p>
      </div>
    </div>

    <!-- Логотип Перекрестка -->
    <div class="logo-containers">
      <img
        src="/logo_black.svg"
        alt="Логотип Перекрёстка"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HotVacanciesCommon",
};
</script>

<style scoped>
/* Стили для intro-section */
.intro-section {
  background-color: #55c350;
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin-top: 20px;
  position: relative;
  text-align: left;
  width: 900px;
  margin: 50px auto;
  font-family: "Gilroy", sans-serif;
  font-weight: normal;
}

.dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dots::before,
.dots::after {
  content: "";
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.intro-section p {
  font-size: 1em;
  font-family: "Gilroy", sans-serif;
  font-weight: normal;
}

/* Стили для блока benefits-icons */
.benefits-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 1000px;
  margin: 70px auto;
}

.benefit-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1em;
  width: 200px;
  font-weight: normal;
}

.benefit-item img {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}

/* Логотип */
.logo-containers {
  display: flex;
  align-items: center;
  margin: 30px 420px;
  width: 900px;
  padding-left: 10px; /* отступ слева для выравнивания с блоком benefits-icons */
}

.logo-containers img {
  width: 527px;
  height: 53px;
}

/* Адаптация под мобильные устройства */
@media (max-width: 768px) {
  .intro-section {
    width: 95% !important;
    padding: 10px !important;
    font-size: 0.9em !important;
    margin: 10px auto !important;
    box-sizing: border-box !important;
  }

  .intro-section p {
    font-size: 1em !important;
    margin: 5px 0 !important;
  }

  .benefits-icons {
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0 10px !important;
    box-sizing: border-box !important;
  }

  .benefit-item {
    width: 100% !important;
    margin-bottom: 15px !important;
    text-align: center !important;
  }

  .benefit-item img {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 5px !important;
  }

  .logo-containers {
    width: 100%;
    justify-content: center;
    margin: 0px auto;
  }

  .logo-containers img {
    width: 100%;
    max-width: 527px;
    height: auto;
    margin: 0px auto;
  }
}
</style>
