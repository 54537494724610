export const vacancies = {
  delivery: {
    type: "delivery",
    title: "Зарабатывай в&nbsp;доставке",
    img: "/delivery_photo.JPG",
    word: "локацию",
    schedule:
      "Скорей заполняй анкету —<br>начнём подбор подходящего<br>графика!",
    text: `Надёжность — это про доставку «Перекрёстка»! У нас всё честно: официальное<br> трудоустройство и регулярные выплаты, а рабочий график подбираем индивидуально.
    <br><br>
    Присоединяйся к команде: выбирай вакансию курьера в доставке.`,
  },
  market: {
    type: "market",
    title: "Зарабатывай в&nbsp;супермаркете",
    img: "/supermarket_photo.JPG",
    word: "супермаркет",
    schedule:
      "Откликайся на вакансию <br>и приводи друзей <br> в «Перекрёсток»: <br> дарим бонусы за рекомендации!",
    text: `Ищешь работу рядом с домом?<br>
    Тебе в «Перекрёсток»!<br>
    Выбирай подходящий супермаркет и удобный график. С тебя — желание развиваться, с нас — честное <br>партнёрство с понятными условиями труда. Начни карьерный путь или продолжай профессиональный <br>рост в стабильных и комфортных условиях.
    <br><br>
    Выбирай вакансию в супермаркетах «Перекрёстка»:<br>
    Мерчандайзер, продавец в категориях овощей и фруктов, морепродуктов и гастрономии, пекарь,<br>кондитер, пиццамейкер, повар коптильни, воков, салатов и суши, бариста, кавист (эксперт по алкоголю), <br>кассир, ассистент или сборщик онлайн-заказов.
      `,
  },
  stock: {
    type: "stock",
    title: "Зарабатывай в распределительном центре",
    img: "/rc_photo.JPG",
    word: "распределительный центр",
    schedule: "Ждём именно тебя.<br>Присоединяйся к команде<br>«Перекрёстка»!",
    text: `Зарабатывай и строй карьеру в распределительных центрах «Перекрёстка».<br>
    Поддержим на старте карьеры, если нет опыта работы, или поможем реализовать амбиции. <br>Предлагаем официальное трудоустройство, белую зарплату, гибкий рабочий график <br>и насыщенную корпоративную жизнь — с праздниками и подарками.
    <br><br>
    Станем партнёрами? Выбирай вакансию в распределительных центрах «Перекрёстка»:<br>
    Водитель электроштабелёра, кладовщик-комплектовщик, приёмосдатчик или оператор отгрузки. `,
  },
};
