<template>
  <div style="display: block">
    <div
      id="rec680785127"
      class="r t-rec"
      style=""
      data-animationappear="off"
      data-record-type="204"
    >
      <!-- cover -->
      <div
        class="t-cover"
        id="recorddiv680785127"
        bgimgfield="img"
        style="
          height: 919px;
        "
      >
        <div
          class="t-cover__carrier loaded"
          id="coverCarry680785127"
          data-content-cover-id="680785127"
          data-content-cover-bg="/0.jpg"
          data-display-changed="true"
          data-content-cover-height="100vh"
          data-content-cover-parallax="fixed"
          style="
            height: 919px;
            background-image: url('0.jpg');
          "
          itemscope=""
          itemtype="http://schema.org/ImageObject"
        >
          <meta
            itemprop="image"
            content="/0.jpg"
          />
        </div>
        <div
          class="t-cover__filter"
          style="
            height: 919px;
            background-image: -webkit-linear-gradient(
              top,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.6)
            );
          "
        ></div>
        <div class="t-container">
          <div class="t-col t-col_8">
            <div
              class="t-cover__wrapper t-valign_middle"
              style="height: 919px; position: relative; z-index: 1"
            >
              <div class="t181">
                <div data-hook-content="covercontent">
                  <div class="t181__wrapper">
                    <div class="t181__title t-title t-title_md" field="title">
                      Памятка перед собеседованием в Перекрёстке
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t672">
      <div class="t-container">
        <div class="t-col t-col_8 t-prefix_2">
          <div class="t672__textwrapper" style="background-color: #54b948">
            <div style="">
              <div field="text" class="t672__text t-heading t-heading_md">
                <strong>Привет! </strong><br />Мы в Перекрёстке искали тебя и
                рады, <br /> что наконец-то нашли:)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec680791468"
      class="r t-rec t-rec_pt_15 t-rec_pb_15 r_showed r_anim"
      style="padding-top: 15px; padding-bottom: 15px"
      data-record-type="673"
    >
      <!-- t673 -->
      <div class="t673">
        <div class="t-container">
          <div class="t-col t-col_8 t-prefix_2">
            <div class="t673__textwrapper" style="border: 5px solid #54b948">
              <div style="margin-left: -5px">
                <div field="text" class="t673__text t-heading t-heading_md">
                  <div style="font-size: 20px" data-customstyle="yes">
                    <span style="color: rgb(0, 0, 0)"
                      >Момент получения первой зарплаты наступит быстрее, если
                      ты принесёшь с собой все необходимые документы уже на
                      собеседование. Так мы сможем начать оформление
                      трудоустройства в этот же день.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec680796744"
      class="r t-rec t-rec_pt_15 t-rec_pb_15 r_anim r_showed"
      style="padding-top: 15px; padding-bottom: 15px"
      data-record-type="673"
    >
      <!-- t673 -->
      <div class="t673">
        <div class="t-container">
          <div class="t-col t-col_8 t-prefix_2">
            <div class="t673__textwrapper" style="border: 5px solid #54b948">
              <div style="margin-left: -5px">
                <div
                  field="text"
                  class="t673__text t-heading_dual t-heading_md t-align_center"
                >
                  <strong style="color: rgb(0, 0, 0)"
                    >Что тебя ждёт на собеседовании?</strong
                  >
                  <div
                    field="text"
                    class="t673__text t-heading_dual t-heading_md t-align_left"
                  >
                    <div style="font-size: 20px">
                      <ul>
                        <li style="color: rgb(0, 0, 0)">
                          <strong style="color: rgb(0, 0, 0)"
                            >Встреча с будущим руководителем (директором
                            супермаркета/начальником склада).</strong
                          >
                        </li>
                      </ul>
                      <small style="color: rgb(0, 2, 2)"
                        >Руководитель расскажет о доступных вакансиях, условиях
                        работы, подберёт удобный график и ответит на все
                        интересующие вопросы.</small
                      ><br />
                      <ul>
                        <li style="color: rgb(0, 0, 0)">
                          <strong style="color: rgb(0, 0, 0)"
                            >Знакомство с рабочим местом.</strong
                          >
                        </li>
                      </ul>
                      <small style="color: rgb(0, 0, 0)"
                        >Если ты захочешь, есть возможность посмотреть будущее
                        рабочее место и попробовать себя на демо-экскурсии (при
                        возможности). </small
                      ><br />
                      <ul>
                        <li style="color: rgb(0, 0, 0)">
                          <strong style="color: rgb(0, 0, 0)"
                            >Завершение собеседования.</strong
                          >
                        </li>
                      </ul>
                      <small style="color: rgb(0, 0, 0)"
                        >После успешного собеседования руководитель выдаст
                        направление на медицинский осмотр (если это необходимо),
                        и мы обменяемся контактами, чтобы уточнить детали
                        дальнейшего взаимодействия.</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec680793276"
      class="r t-rec t-rec_pt_15 t-rec_pb_15 r_showed r_anim"
      style="padding-top: 15px; padding-bottom: 15px"
      data-record-type="672"
    >
      <!-- t672 -->
      <div class="t672">
        <div class="t-container">
          <div class="t-col t-col_8 t-prefix_2">
            <div class="t672__textwrapper" style="background-color: #54b948">
              <div style="">
                <div
                  field="text"
                  class="t672__text t-heading t-heading_md t-align_center"
                >
                  <div style="font-size: 20px" data-customstyle="yes">
                    Список документов<br />
                    <div
                      field="text"
                      class="t672__text t-heading t-heading_md t-align_left"
                    >
                      <div style="font-size: 20px" data-customstyle="yes">
                        Для граждан РФ:
                        <ul>
                          <li>паспорт</li>
                          <li>
                            трудовая книжка (бумажная) или справка СТД-Р (если
                            трудовая в электронном виде)
                          </li>
                          <li>
                            справка СТД-ПФР (запросить можно через Госуслуги)
                          </li>
                          <li>СНИЛС</li>
                          <li>ИНН</li>
                          <li>медицинская книжка (при наличии)</li>
                          <li>военный билет (для военнообязанных)</li>
                          <li>банковские реквизиты</li>
                        </ul>
                        <br />Для иностранных граждан:<br />
                        <ul>
                          <li>
                            документ, удостоверяющий личность:
                            паспорт/идентификационная карточка/заграничный
                            паспорт/удостоверение беженца/свидетельство о
                            предоставлении врем. убежища + перевод
                          </li>
                          <li>
                            БТК/СТД-ПФР/заявление на ЭТК+СТД ПФР (при утере)
                          </li>
                          <li>СНИЛС</li>
                          <li>ИНН (при наличии)</li>
                          <li>миграционная карта</li>
                          <li>
                            бланк уведомления о регистрации по месту
                            пребывания/РВП/ВНЖ
                          </li>
                          <li>банковские реквизиты</li>
                          <li>патент + подтверждение оплаты</li>
                        </ul>
                        <br />Дополнительно рекомендуем взять:<br />
                        <ul>
                          <li>справка 182-н (от предыдущего работодателя)</li>
                          <li>документ об образовании</li>
                          <li>
                            свидетельство о заключении брака/рождении детей (при
                            наличии)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec680799705"
      class="r t-rec t-rec_pt_15 t-rec_pb_45 r_anim r_showed"
      style="padding-top: 15px; padding-bottom: 45px"
      data-record-type="672"
    >
      <!-- t672 -->
      <div class="t672">
        <div class="t-container">
          <div class="t-col t-col_8 t-prefix_2">
            <div class="t672__textwrapper" style="background-color: #54b948">
              <div style="">
                <div field="text" class="t672__text t-heading t-heading_md">
                  <div style="font-size: 20px" data-customstyle="yes">
                    <strong
                      >Если изменятся планы, и ты не сможешь прийти в
                      назначенный день, пожалуйста, сообщи нам заранее по
                      номеру: 8-800-550-11-00. </strong
                    ><br /><strong
                      >Мы ждём тебя, и готовы рассказать всё о прозрачных
                      условиях работы в технологичном ритейле.</strong
                    ><br /><br /><strong
                      >До скорой встречи в Перекрёстке!</strong
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec683772858"
      class="r t-rec t-rec_pt_60 t-rec_pb_90"
      style="padding-top: 0px; padding-bottom: 0px; background-color: #efefef"
      data-animationappear="off"
      data-record-type="923"
      data-bg-color="#efefef"
    >
      <!-- t923 -->
      <div class="t923">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                Рекомендуй друзьям работу в Перекрёстке
              </div>
              <div
                class="t-section__descr t-descr t-descr_xl t-margin_auto"
                field="bdescr"
              >
                В нашей команде всегда рады хорошим специалистам
              </div>
            </div>

            <carousel v-bind="settings" :perPage="1">
              <slide v-for="(slide, i) in slides" :index="i">
                <div class="carousel__item">
                  <div class="t923__width_12 t-margin_auto t-width">
                    <div
                      class="t923__slds-wrapper t-slds__wrapper t-align_center"
                    >
                      <div
                        class="t923__col t-col t-align_left"
                        style="width: 260px"
                      >
                        <div
                          class="t923__wrapper"
                          style=""
                          data-blocks-per-row="4"
                        >
                          <div
                            class="t923__imgwrapper t923__imgwrapper_1-1"
                            style="background-color: #ffffff"
                            itemscope=""
                            itemtype="http://schema.org/ImageObject"
                          >
                            <meta itemprop="image" content="" />
                            <div
                              class="t923__bgimg t-bgimg loaded"
                              v-bind:style="{
                                'background-image': 'url(' + slide.src + ')',
                              }"
                            ></div>
                          </div>
                          <div
                            class="t923__content"
                            style="
                              background-color: rgb(255, 255, 255);
                              height: 220px;
                            "
                          >
                            <div class="t923__textwrapper">
                              <div class="t-card__title t-name t-name_xs">
                                <a
                                  v-bind:href="slide.hr"
                                  v-bind:id="slide.id"
                                  class="t-card__link"
                                  tabindex="0"
                                >
                                  {{ slide.title }}</a
                                >
                              </div>
                              <div
                                class="t-card__descr t-descr t-descr_xxs"
                                field="li_descr__3671421999890"
                              >
                                <small> {{ slide.content }}</small>
                              </div>
                            </div>
                            <div class="t-card__btn-wrapper t-align_center">
                              <div
                                class="t-card__btn t-btn t-btn_xs t-align_center"
                                id="cardbtn1_683772858"
                                style="
                                  color: #000000;
                                  background-color: #009900;
                                  border-radius: 0px;
                                  -moz-border-radius: 5px;
                                  -webkit-border-radius: 5px;
                                "
                                data-field="li_buttontitle__3671421999890"
                                aria-hidden="true"
                                data-buttonfieldset="li_button"
                                data-lid="3671421999890"
                              >
                                <a v-bind:href="slide.hr"
                                  ><strong>Заполнить анкету</strong>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from "vue-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      slides: [
        {
          id: 1,
          title: "Сборщик заказов",
          content: "Удобный график от 4-х часов в день и надёжная подработка",
          src: "1.jpg",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 2,
          title: "Кавист",
          content:
            "Престижная профессия эксперта по продаже алкоголя с бесплатным обучением",
          src: "2.png",
          hr: "/vacancy/stock?=undefined",
        },
        {
          id: 3,
          title: "Кассир в супермаркет",
          content: "Быстрое трудоустройство и стабильные выплаты",
          src: "3.jpg",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 4,
          title: "Лидер торгового зала",
          content: "Карьерный взлёт в технологичном ритейле",
          src: "4.jpg",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 5,
          title: "Мастер мясного дела",
          content: "Востребованная профессия в престижном супермаркете",
          src: "5.png",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 6,
          title: "Продавец в торговом зале",
          content: "Отпуска и больничные за счёт работодателя",
          src: "6.png",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 7,
          title: "Товаровед",
          content: "Взаимодействие с поставщиками и директором супермаркета",
          src: "7.png",
          hr: "/vacancy/market?=undefined",
        },
        {
          id: 8,
          title: "Сотрудник распределительного центра",
          content: "Вакансии кладовщиков и приёмосдатчиков со сменным графиком",
          src: "8.png",
          hr: "/vacancy/stock?=undefined",
        },
        {
          id: 9,
          title: "Повар или пекарь",
          content: "Официальное трудоустройство и профессиональные конкурсы",
          src: "9.png",
          hr: "/vacancy/market?=undefined",
        },
      ],
      settings: {
       snapAlign: "center",
        navigationEnabled: true,
        loop: true,
        paginationColor: "#efefef",
        paginationActiveColor: "#efefef",
        perPageCustom: [
          [100, 1],
          [800, 1],
          [1024, 1],
          [1800, 4],
        ],
      },
    };
  },
};
</script>

<style scoped>

ol li, ul li {
  list-style-type: circle;
}
.VueCarousel-dot-container {
  background-color: rgb(0, 0, 0);
}
.csssize {
  height: 5403px;
}

.t-body {
  margin: 0;
}

#allrecords {
  -webkit-font-smoothing: antialiased;
  background-color: none;
}

#allrecords a {
  color: #ff8562;
  text-decoration: none;
}

#allrecords a,
#allrecords button,
#allrecords .t-radio,
#allrecords .t-checkbox,
#allrecords .t-img-select {
  outline: none;
}

#allrecords a:focus-visible,
#allrecords button:focus-visible,
#allrecords .t-focusable,
#allrecords .t-focusable .t-btn,
#allrecords .t-focusable .t-btntext,
#allrecords .t-img-select:focus-visible + .t-img-select__indicator,
#allrecords .t-img-select:focus-visible + .t-img-select__indicator:after,
#allrecords .t-radio:focus-visible + .t-radio__indicator,
#allrecords .t-checkbox:focus-visible + .t-checkbox__indicator {
  outline-color: #2015ff;
  outline-offset: 4px;
  outline-style: auto;
}

#allrecords .t-descr a:focus-visible,
#allrecords .t-sociallinks a:focus-visible,
#allrecords .ya-share2__link:focus-visible {
  outline-offset: 2px;
}

#allrecords a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

#allrecords ol {
  padding-left: 22px;
}

#allrecords ul {
  padding-left: 20px;
}

#allrecords b,
#allrecords strong {
  font-weight: 700;
}

@media print {
  body,
  html {
    min-width: 1200px;
    max-width: 1200px;
    padding: 0;
    margin: 0 auto;
    border: none;
  }
}

.t-records {
  -webkit-font_smoothing: antialiased;
  background-color: none;
}

.t-records a {
  color: #ff8562;
  text-decoration: none;
}

.t-records a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

.t-records ol {
  padding-left: 22px;
  margin-top: 0;
  margin-bottom: 10px;
}

.t-records ul {
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

.t-mbfix {
  opacity: 0.01;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  position: fixed;
  width: 100%;
  height: 500px;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 10000;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.t-mbfix_hide {
  -webkit-transform: translateX(3000px);
  -ms-transform: translateX(3000px);
  transform: translateX(3000px);
}

img:not([src]) {
  visibility: hidden;
}

.t-body_scroll-locked {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.r_anim {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.r_hidden {
  opacity: 0;
}

.r_showed {
  opacity: 1;
}

.t-name {
  font-family: "Gilroy", Arial, sans-serif;
  font-weight: 600;
  color: #000000;
}

.t-name_xs {
  font-size: 16px;
  line-height: 1.35;
}

.t-name_md {
  font-size: 20px;
  line-height: 1.35;
}

.t-name_lg {
  font-size: 22px;
  line-height: 1.35;
}

.t-heading {
  font-family: "Gilroy", Arial, sans-serif;
  font-weight: 600;
  color: #000000;
}

.t-heading_dual {
  font-family: "Gylroy", Arial, sans-serif;
  font-weight: 100;
  color: #000000;
}

.t-heading_md {
  font-size: 30px;
  line-height: 1.17;
}

.t-title {
  font-family: "Gilroy", Arial, sans-serif;
  font-weight: 600;
  color: #000000;
}

.t-title_xs {
  font-size: 42px;
  line-height: 1.23;
}

.t-title_md {
  font-size: 52px;
  line-height: 1.23;
}

.t-descr {
  font-family: "Gilroy", Arial, sans-serif;
  font-weight: 300;
  color: #000000;
}

.t-descr_xxs {
  font-size: 14px;
  line-height: 1.55;
}

.t-descr_xl {
  font-size: 24px;
  line-height: 1.5;
}

.t-btn {
  display: inline-block;
  font-family: "Gilroy", Arial, sans-serif;
  border: 0 none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 700;
  background-image: none;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.t-btn td {
  vertical-align: middle;
}

.t-btn_sending {
  opacity: 0.5;
}

.t-btn_xs {
  height: 35px;
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #000000;
}

a,
.green {
  color: #000000;
}

#app {
  padding: 0px;
}

.carousel {
  --vc-nav-width: 70px;
  --vc-nav-height: 70px;
  --vc-nav-color: #54b948;
}
.t-align_center {
  text-align: center;
}

.t-align_left {
  text-align: left;
}

.t-margin_auto {
  margin-left: auto;
  margin-right: auto;
}

.t-valign_middle {
  vertical-align: middle;
}

.t-rec_pt_15 {
  padding-top: 15px;
}

.t-rec_pt_60 {
  padding-top: 60px;
}

.t-rec_pb_15 {
  padding-bottom: 15px;
}

.t-rec_pb_45 {
  padding-bottom: 45px;
}

.t-rec_pb_90 {
  padding-bottom: 90px;
}

@media screen and (max-width: 1200px) {
  .t-descr_xxs {
    font-size: 12px;
  }

  #app {
    padding: 0px;
  }

  .t-descr_xl {
    font-size: 22px;
  }

  .t-title_xs {
    font-size: 38px;
  }

  .t-title_md {
    font-size: 48px;
  }

  .t-name_xs {
    font-size: 14px;
  }

  .t-name_md {
    font-size: 18px;
  }

  .t-name_lg {
    font-size: 20px;
  }

  .t-heading_md {
    font-size: 28px;
  }
}

@media screen and (max-width: 640px) {
  .t-descr_xl {
    font-size: 20px;
    line-height: 1.4;
  }

  .t-title_xs {
    font-size: 30px;
  }

  .t-title_md {
    font-size: 30px;
  }

  .t-name_xs {
    font-size: 12px;
  }

  .t-name_md {
    font-size: 16px;
  }

  .t-name_lg {
    font-size: 18px;
  }

  .t-heading_md {
    font-size: 24px;
  }

  .t-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 480px) {
  .t-rec_pt_15 {
    padding-top: 15px;
  }

  .t-rec_pt_60 {
    padding-top: 60px;
  }

  .t-rec_pb_15 {
    padding-bottom: 15px;
  }

  .t-rec_pb_45 {
    padding-bottom: 45px;
  }

  .t-rec_pb_90 {
    padding-bottom: 90px;
  }

  .t-btn_xs {
    height: 40px;
  }
}

@font-face {
  font-family: "Gilroy";
  src: url("../../public/fonts/Gilroy-Light.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../public/fonts/Gilroy-ExtraBold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.t181 {
  text-align: left;
}

.t181__wrapper {
  padding-top: 42px;
  padding-bottom: 42px;
}

.t181__title {
  padding: 24px 0 38px 0;
  letter-spacing: 1px;
  color: #fff;
}

.t181__descr {
  padding: 0 0 30px 0;
  color: #afafaf;
}

.t181 .t-btnwrapper {
  display: inline-block;
}

.t181 :not(.t-btnwrapper) .t-btn:nth-child(1),
.t181 .t-btnwrapper:nth-child(1) {
  margin-right: 10px;
}

.t181 :not(.t-btnwrapper) .t-btn,
.t181 .t-btnwrapper {
  margin-top: 8px;
}

.t672__textwrapper {
  margin-left: -60px;
  padding: 60px;
}

.t-col_12 .t672__textwrapper {
  margin-left: -40px;
}

.t672__text {
  color: #fff;
  line-height: 50px;
}

.t672 .t-col_1 {
  max-width: 120px;
}

.t672 .t-col_2 {
  max-width: 220px;
}

.t672 .t-col_3 {
  max-width: 320px;
}

.t672 .t-col_4 {
  max-width: 420px;
}

.t672 .t-col_5 {
  max-width: 520px;
}

.t672 .t-col_6 {
  max-width: 620px;
}

.t672 .t-col_7 {
  max-width: 720px;
}

.t672 .t-col_8 {
  max-width: 820px;
}

.t672 .t-col_9 {
  max-width: 920px;
}

.t672 .t-col_10 {
  max-width: 1020px;
}

.t672 .t-col_11 {
  max-width: 1120px;
}

.t672 .t-col_12 {
  max-width: 1220px;
}

@media screen and (max-width: 1240px) {
  .t672 .t-col_12 {
    max-width: 1160px;
  }

  .t-col_12 .t672__textwrapper {
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .t672__textwrapper {
    margin-left: -40px;
    padding: 40px;
  }

  .t-col_12 .t672__textwrapper {
    margin-left: -20px;
  }

  .t672 .t-col_1 {
    max-width: 100px;
  }

  .t672 .t-col_2 {
    max-width: 180px;
  }

  .t672 .t-col_3 {
    max-width: 260px;
  }

  .t672 .t-col_4 {
    max-width: 340px;
  }

  .t672 .t-col_5 {
    max-width: 420px;
  }

  .t672 .t-col_6 {
    max-width: 500px;
  }

  .t672 .t-col_7 {
    max-width: 580px;
  }

  .t672 .t-col_8 {
    max-width: 660px;
  }

  .t672 .t-col_9 {
    max-width: 740px;
  }

  .t672 .t-col_10 {
    max-width: 820px;
  }

  .t672 .t-col_11 {
    max-width: 900px;
  }

  .t672 .t-col_12 {
    max-width: 980px;
  }
}

@media screen and (max-width: 980px) {
  .t672 .t-col_12 {
    max-width: 940px;
  }
}

@media screen and (max-width: 960px) {
  .t672 .t-container {
    max-width: 680px;
  }

  .t672__textwrapper,
  .t-col_12 .t672__textwrapper {
    margin-left: 0;
    padding: 40px;
  }

  .t672 .t-col {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 700px) {
  .t672__textwrapper {
    margin-left: 0;
    padding: 20px;
  }

  .t672 .t-container {
    max-width: 640px;
  }
}

.t673__textwrapper {
  margin-left: -60px;
  padding: 60px;
}

.t-col_12 .t673__textwrapper {
  margin-left: -40px;
}

.t673__text {
  color: #fff;
  line-height: 50px;
}

.t673 .t-col_1 {
  max-width: 120px;
}

.t673 .t-col_2 {
  max-width: 220px;
}

.t673 .t-col_3 {
  max-width: 320px;
}

.t673 .t-col_4 {
  max-width: 420px;
}

.t673 .t-col_5 {
  max-width: 520px;
}

.t673 .t-col_6 {
  max-width: 620px;
}

.t673 .t-col_7 {
  max-width: 720px;
}

.t673 .t-col_8 {
  max-width: 820px;
}

.t673 .t-col_9 {
  max-width: 920px;
}

.t673 .t-col_10 {
  max-width: 1020px;
}

.t673 .t-col_11 {
  max-width: 1120px;
}

.t673 .t-col_12 {
  max-width: 1220px;
}

@media screen and (max-width: 1240px) {
  .t673 .t-col_12 {
    max-width: 1160px;
  }

  .t-col_12 .t673__textwrapper {
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .t673__textwrapper {
    margin-left: -40px;
    padding: 40px;
  }

  .t-col_12 .t673__textwrapper {
    margin-left: -20px;
  }

  .t673 .t-col_1 {
    max-width: 100px;
  }

  .t673 .t-col_2 {
    max-width: 180px;
  }

  .t673 .t-col_3 {
    max-width: 260px;
  }

  .t673 .t-col_4 {
    max-width: 340px;
  }

  .t673 .t-col_5 {
    max-width: 420px;
  }

  .t673 .t-col_6 {
    max-width: 500px;
  }

  .t673 .t-col_7 {
    max-width: 580px;
  }

  .t673 .t-col_8 {
    max-width: 660px;
  }

  .t673 .t-col_9 {
    max-width: 740px;
  }

  .t673 .t-col_10 {
    max-width: 820px;
  }

  .t673 .t-col_11 {
    max-width: 900px;
  }

  .t673 .t-col_12 {
    max-width: 980px;
  }
}

@media screen and (max-width: 980px) {
  .t673 .t-col_12 {
    max-width: 940px;
  }
}

@media screen and (max-width: 960px) {
  .t673 .t-container {
    max-width: 680px;
  }

  .t673__textwrapper,
  .t-col_12 .t673__textwrapper {
    margin-left: 0;
    padding: 40px;
  }

  .t673 .t-col {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 700px) {
  .t673__textwrapper {
    margin-left: 0;
    padding: 20px;
  }

  .t673 .t-container {
    max-width: 640px;
  }
}

.t824__body_popupshowed {
  -webkit-overflow-scrolling: touch !important;
}

.t824 {
  position: relative;
  z-index: 1000;
}

.t824.t824_active {
  z-index: 100000;
}

.t824__btn {
  position: fixed;
  bottom: 50px;
  right: 65px;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.t824__btn_wrapper {
  overflow: hidden;
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.t824__btn-text {
  position: absolute;
  right: 80px;
  white-space: nowrap;
  background: #fff;
  padding: 9px 13px;
  font-size: 15px;
  border-radius: 3px;
  -webkit-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
  top: 50%;
  background: #292929;
  color: #fff;
  opacity: 0.85;
  -webkit-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.t824__btn-text::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 10px;
  top: 50%;
  right: -20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left-color: #292929;
}

.t824__btn_active .t824__btn_wrapper {
  background: #fff !important;
}

.t824__btn_active .t824__btn_wrapper::after {
  visibility: hidden;
}

.t824__btn_active .t824__btn-text {
  visibility: hidden;
  opacity: 0;
}

.t824__btn_animate {
  animation: t824__btn-pulsate 2s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: t824__btn-pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes t824__btn-pulsate {
  0% {
    -webkit-transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(1.1, 1.1);
  }

  50% {
    -webkit-transform: scale(1, 1);
  }
}

@keyframes t824__btn-pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }

  50% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.t824__btn_animate .t824__animated-circle {
  position: absolute;
  width: 60px;
  height: 60px;
  top: -3px;
  left: -3px;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  border: 3px solid #199c68;
  -webkit-animation: t824__pulsate 1s ease-out;
  animation: t824__pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: t824__pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
}

@-webkit-keyframes t824__pulsate {
  0% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0;
  }
}

@keyframes t824__pulsate {
  0% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0;
  }

  25% {
    opacity: 0.4;
  }

  50% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(0.8, 0.8);
    opacity: 0;
  }
}

.t824__icon,
.t824__icon-close {
  fill: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.t824__btn-img {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.t824__icon-close,
.t824__btn_active .t824__icon,
.t824__btn_active .t824__btn-img {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
}

.t824__btn_active .t824__icon-close {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
}

.t824__popup {
  display: none;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.2s;
  -o-transition: opacity ease-in-out 0.2s;
  transition: opacity ease-in-out 0.2s;
}

.t824__popup_show {
  opacity: 1;
}

.t824__popup-container {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  top: initial;
  left: initial;
  bottom: 50px;
  right: 65px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4);
  background: #fff;
  position: fixed;
  z-index: 1;
  cursor: default;
  max-width: 360px;
}

.t824__popup-container_show {
  visibility: visible;
  opacity: 1;
}

.t824__wrapper {
  position: relative;
  padding-top: 48px;
  padding-bottom: 20px;
  border-radius: 5px;
  max-height: 65vh;
  text-align: center;
}

.t824__popup-close {
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 6px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.t824__popup-icon-close {
  display: block;
  width: 12px;
  height: 12px;
}

.t824__btn-img,
.t824__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.t824__btn-img {
  width: 60px;
  height: 60px;
}

.t824__imgwrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.t824__image {
  width: 70px;
  height: 70px;
}

.t824__image-wrapper {
  position: absolute;
  top: -35px;
  left: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  background: #ffffff;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.t824__bgimg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.t824__phone {
  display: block;
}

.t824__text-wrapper,
.t824__phone-wrapper {
  padding-left: 35px;
  padding-right: 35px;
}

.t824__text-wrapper {
  padding-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
}

.t824__phone-wrapper {
  margin-top: 17px;
}

.t824__mobile-top-panel {
  visibility: hidden;
  width: 100%;
  height: 50px;
  background: #000;
  position: fixed;
  display: table;
}

.t824__mobile-top-panel_wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  width: 100%;
}

.t824__mobile-close {
  padding: 0;
  border: none;
  background-color: transparent;
}

#allrecords .t824__mobile-close:focus-visible {
  outline-offset: -3px;
}

.t824__mobile-icon-close {
  vertical-align: middle;
  cursor: pointer;
  padding: 15px;
}

@media screen and (max-width: 960px) {
  .t824__btn {
    visibility: visible !important;
    opacity: 1 !important;
    bottom: 10px !important;
    right: 10px !important;
  }

  .t824__btn-text {
    font-size: 12px;
  }

  .t824__btn-text::after {
    border-width: 8px;
    right: -16px;
  }

  .t824__btn_active {
    display: none;
    opacity: 0;
  }

  .t824__wrapper {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 80px;
    max-height: initial;
    overflow-y: initial;
  }

  .t824__popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  .t824__popup-container {
    position: initial;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    height: auto;
    border-radius: 0;
    overflow: initial;
  }

  .t824__image-wrapper {
    position: static;
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .t824__mobile-top-panel {
    visibility: visible;
    z-index: 1;
  }
}

@media screen and (max-width: 640px) {
  .t824 .t-input-block textarea {
    padding-top: 12px;
  }
}

.t923 .t-section__topwrapper {
  margin-bottom: 0px;
}

.t923 .t-slds__bullet_wrapper {
  display: none;
}

.t923 .t-section__title {
  margin-bottom: 40px;
}

.t923 .t-section__descr {
  max-width: 560px;
}

.t923 .t-section__bottomwrapper {
  margin-top: 105px;
}

.t923 li ul {
  list-style-type: disc;
}

.t923 .t-slds__arrow_wrapper {
  width: 5%;
}

.t923__slds-wrapper {
  -webkit-flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
  font-size: 0;
}

.t923__img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}

.t923__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}

.t923__width_6 {
  max-width: 600px;
}

.t923__width_7 {
  max-width: 700px;
}

.t923__width_8 {
  max-width: 800px;
}

.t923__width_9 {
  max-width: 900px;
}

.t923__width_10 {
  max-width: 1000px;
}

.t923__width_11 {
  max-width: 1100px;
}

.t923__width_12 {
  max-width: 1200px;
}

.t923__width_100 {
  max-width: 100%;
}

@media screen and (max-width: 1300px) {
  .t923 .t-slds__container {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .t923__item {
    padding: 10px;
  }

  .t923__width_6 {
    max-width: 480px;
  }

  .t923__width_7 {
    max-width: 560px;
  }

  .t923__width_8 {
    max-width: 640px;
  }

  .t923__width_9 {
    max-width: 720px;
  }

  .t923__width_10 {
    max-width: 800px;
  }

  .t923__width_11 {
    max-width: 880px;
  }

  .t923__width_12 {
    max-width: 960px;
  }

  .t923__width_100 {
    max-width: 100%;
  }
}

.t923 .t-section__topwrapper {
  margin-bottom: 90px;
}

.t923 .t-section__title {
  margin-bottom: 20px;
}

.t923 .t-section__descr {
  max-width: 560px;
}

.t923 .t-section__bottomwrapper {
  margin-top: 105px;
}

.t923__separator {
  margin-bottom: 40px;
}

.t923__wrapper {
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.t923__imgwrapper {
  position: relative;
  width: 100%;
}

.t923__imgwrapper_16-9 {
  padding-bottom: 56%;
}

.t923__imgwrapper_4-3 {
  padding-bottom: 75%;
}

.t923__imgwrapper_3-2 {
  padding-bottom: 66%;
}

.t923__imgwrapper_1-1 {
  padding-bottom: 100%;
}

.t923__imgwrapper_2-3 {
  padding-bottom: 0%;
}

.t923__imgwrapper_3-4 {
  padding-bottom: 0%;
}

.t923__imgwrapper_9-16 {
  padding-bottom: 0%;
}

.t923__bgimg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

.t923__bgimg_second {
  z-index: 1;
  opacity: 0;
}

.t923__content {
  position: relative;
}

.t923__textwrapper {
  padding: 15px 15px;
  box-sizing: border-box;
}

.t923__textwrapper.t923__paddingsmall {
  padding: 18px 20px;
}

.t923__textwrapper.t923__paddingbig {
  padding: 36px 40px;
}

.t923 .t-card__uptitle {
  margin-bottom: 8px;
  color: #ff8562;
}

.t923 .t-card__descr {
  margin-top: 8px;
}

.t923__price-wrapper {
  margin-top: 12px;
}

.t923__price-item {
  display: inline-block;
  vertical-align: middle;
}

.t923 .t-card__btn-wrapper,
.t923 .t-card__btntext-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 22px 10px;
  box-sizing: border-box;
}

.t923 .t-card__btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.t923 .t-align_center .t-card__btn,
.t923 .t-align_center .t-card__btn-text {
  margin: 0 2px 8px;
}

.t923 .t-align_left .t-card__btn,
.t923 .t-align_left .t-card__btn-text {
  margin: 0 4px 8px 0;
}

.t923__paddingbig.t-card__btn-wrapper,
.t923__paddingbig.t-card__btntext-wrapper {
  padding: 0 40px 28px;
}

.t923__paddingsmall.t-card__btn-wrapper,
.t923__paddingsmall.t-card__btntext-wrapper {
  padding: 0 16px 14px;
}

.t923__scroll-icon-wrapper {
  display: none;
}

@media screen and (min-width: 960px) {
  .t923__imgwrapper:hover .t923__bgimg_second {
    z-index: 3;
    opacity: 1;
  }
}

@media screen and (max-width: 960px) {
  .t923__separator {
    display: none;
  }

  .t923__col {
    margin-bottom: 40px;
  }

  .t923__img_second {
    display: none;
  }
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .t923__bgimg,
  .t923__wrapper {
    right: -0.99px;
  }
}

@media screen and (max-width: 960px) {
  .t923 .t-section__bottomwrapper {
    margin-top: 45px;
  }

  .t923 .t923__slds-wrapper .t-col {
    padding: 0;
  }

  .t923 .t923__slds-wrapper .t923__col {
    margin-bottom: 0;
  }

  .t923 .t-section__topwrapper {
    margin-bottom: 45px;
  }

  .t923 .t-section__title {
    margin-bottom: 20px;
  }

  .t923__item_6-in-row {
    width: 33.333%;
  }
}

@media screen and (max-width: 640px) {
  .t923__item_4-in-row {
    width: 50%;
  }

  .t923 .t923__slds-wrapper .t-col {
    padding: 0 30px;
  }
}

@media screen and (max-width: 440px) {
  .t923 .t-width .t-slds__wrapper,
  .t923 .t923__slds-wrapper .t-col {
    padding: 0 5px;
  }

  .t923__item_6-in-row {
    width: 50%;
  }
}

rec683772858 .t-slds__bullet:hover .t-slds__bullet_body {
  background-color: #222 !important;
}
rec683772858 .t-slds__bullet_active .t-slds__bullet_body {
  background-color: #222 !important;
}

@-webkit-keyframes t-arrow-bottom {
  0%,
  to {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  50%,
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

@keyframes t-arrow-bottom {
  0%,
  to {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  50%,
  55% {
    -moz-transform: translateY(-7px);
    -ms-transform: translateY(-7px);
    -webkit-transform: translateY(-7px);
    -o-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

.t-cover,
.t-cover__carrier {
  height: 700px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  vertical-align: middle;
  position: relative;
  background-attachment: fixed;
}

.t-cover {
  background-color: #000;
  overflow: hidden;
}

.t-cover.t-cover__transparent {
  background-color: transparent;
}

.t-cover__carrier {
  -moz-transform: unset;
}

@supports (-ms-ime-align: auto) {
  .t-cover__carrier {
    transform: unset;
  }
}

.t-cover__carrier.loading {
  opacity: 0;
}

.t-cover__carrier[data-content-cover-bg=""].loading {
  opacity: 1 !important;
}

.t-cover__carrier.loaded {
  opacity: 1;
  transition: opacity 700ms;
}

@media screen and (max-device-width: 1024px) {
  .t-cover,
  .t-cover__carrier {
    background-attachment: scroll;
  }
}

@media print {
  .t-cover,
  .t-cover__carrier {
    background-attachment: scroll;
  }
}

.t-cover__filter {
  height: 700px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.t-cover .t-container,
.t-cover .t-container_10,
.t-cover .t-container_100,
.t-cover .t-container_8 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.t-cover__wrapper {
  height: 700px;
  display: table-cell;
  width: 1200px;
}

.t-cover__wrapper span.space {
  display: inline-block;
  height: 100%;
  width: 1px;
}

@media screen and (max-width: 640px) {
  .t-cover {
    background-attachment: fixed;
  }

  .t-cover__carrier {
    background-attachment: scroll !important;
    background-size: cover;
    background-position: center center;
  }
}

.t-cover__arrow-wrapper_animated {
  animation: t-arrow-bottom 1.7s infinite ease;
}

.t-cover__arrow {
  position: absolute;
  z-index: 9;
  bottom: 40px;
  right: 0;
  left: 0;
  text-align: center;
}

.t-cover__arrow-wrapper {
  display: inline-block;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.t-cover__arrow-wrapper:hover {
  opacity: 0.7;
}

.t-cover__arrow-svg {
  fill: #fff;
}

@media screen and (max-width: 640px) {
  .t-cover__arrow_mobile {
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }

  .t-cover__arrow {
    bottom: 14px;
  }
}
#allrecords ul.t-cards__container {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

#allrecords .t-cards__col .t-cards__link {
  color: inherit !important;
}

#allrecords ul.t-card__container {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

ul.t-card__container li ul {
  list-style-type: disc;
}

#allrecords .t-card__col .t-card__link,
#allrecords .t-card__col .t-card__link_second {
  color: inherit !important;
  outline: none;
}

#allrecords .t-focusable,
#allrecords .t-focusable .t-btn,
#allrecords .t-focusable .t-btntext {
  position: relative;
}
.t-slds {
  position: relative;
  overflow: hidden;
}

.t-slds__bgimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.t-slds__bgimg-contain {
  background-size: contain;
}

.t-slds__iframe {
  position: relative;
}

.t-slds__iframe__body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.t-slds__img {
  display: block;
  width: 100%;
}

.t-slds__container {
  overflow: hidden;
  position: relative;
}

.t-slds__items-wrapper {
  position: relative;
  overflow: hidden;
}

.t-store__relevants-grid-cont.t-store__valign-buttons .t-slds__items-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.t-slds__nocycle .t-slds__arrow_wrapper-left {
  display: none;
}

.t-slds__items-wrapper:before,
.t-slds__items-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.t-slds_animated.t-slds_animated-fast,
.t-slds_animated.t-slds_animated-none {
  -webkit-transition: height ease-in-out 0.3s, transform ease-in-out 0.3s;
  -moz-transition: height ease-in-out 0.3s, transform ease-in-out 0.3s;
  -o-transition: height ease-in-out 0.3s, transform ease-in-out 0.3s;
  transition: height ease-in-out 0.3s, transform ease-in-out 0.3s;
}

.t-slds_animated.t-slds_animated-slow {
  -webkit-transition: height ease-in-out 0.5s, transform ease-in-out 0.5s;
  -moz-transition: height ease-in-out 0.5s, transform ease-in-out 0.5s;
  -o-transition: height ease-in-out 0.5s, transform ease-in-out 0.5s;
  transition: height ease-in-out 0.5s, transform ease-in-out 0.5s;
}

.t-slds_animated.t-slds_animated-cancel {
  -webkit-transition: height ease-in-out 0.3s, transform 0s;
  -moz-transition: height ease-in-out 0.3s, transform 0s;
  -o-transition: height ease-in-out 0.3s, transform 0s;
  transition: height ease-in-out 0.3s, transform 0s;
}

.t-slds__item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#allrecords li.t-slds__item {
  list-style: none;
}

.t-slds_ie .t-slds__item {
  display: none !important;
}

.t-slds_ie .t-slds__item_active {
  display: block !important;
}

#allrecords ul.t-slds__arrow_container {
  margin: 0 auto;
  padding-left: 0;
}

.t-slds__arrow_container-outside {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.t-slds__arrow_container.t-slds__arrow_container-outside {
  margin: 0 auto;
  box-sizing: border-box;
}

.t-slds__arrow_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1%;
  cursor: pointer;
  z-index: 15;
  list-style: none;
  -webkit-transition: height ease-in-out 0.3s;
  -moz-transition: height ease-in-out 0.3s;
  -o-transition: height ease-in-out 0.3s;
  transition: height ease-in-out 0.3s;
}

.t-slds__arrow_wrapper-left {
  left: 0;
}

.t-slds__arrow_wrapper-right {
  right: 0;
}

.t-slds__arrow {
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  outline-offset: 5px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .t-store__relevants__container .t-slds__arrow {
    padding: 20px;
  }

  .t-store__relevants__container .t-slds__arrow-left {
    transform: translateY(-50%) translateX(-20px);
  }

  .t-store__relevants__container .t-slds__arrow-right {
    transform: translateY(-50%) translateX(20px);
  }

  .t-store__relevants__container .t-slds__arrow.t-slds__arrow-withbg {
    padding: 0;
  }

  .t-slds__arrow_wrapper {
    width: 45px;
  }
}

.t-slds__arrow-left {
  left: 30px;
}

.t-slds__arrow-right {
  right: 30px;
}

@media screen and (min-width: 960px) {
  .t-slds__arrow-nearpic .t-slds__arrow-left {
    right: 40px;
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    left: auto;
  }

  .t-slds__arrow-nearpic .t-slds__arrow-right {
    left: 40px;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: auto;
  }

  .t-slds__arrow-inpic .t-slds__arrow-left {
    right: -40px;
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    -o-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    left: auto;
  }

  .t-slds__arrow-inpic .t-slds__arrow-right {
    left: -40px;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: auto;
  }
}

@media screen and (min-width: 1200px) {
  .t-slds__arrow-nearpic .t-slds__arrow-left {
    right: 50px;
  }

  .t-slds__arrow-nearpic .t-slds__arrow-right {
    left: 50px;
  }

  .t-slds__arrow-inpic .t-slds__arrow-left {
    right: -50px;
  }

  .t-slds__arrow-inpic .t-slds__arrow-right {
    left: -50px;
  }
}

.t-slds__arrow-withbg {
  border-radius: 100px;
  background-color: #eee;
  -webkit-transition: background-color ease-in-out 0.2s,
    border-color ease-in-out 0.2s;
  -moz-transition: background-color ease-in-out 0.2s,
    border-color ease-in-out 0.2s;
  -o-transition: background-color ease-in-out 0.2s,
    border-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s, border-color ease-in-out 0.2s;
}

.t-slds__arrow-withbg .t-slds__arrow_body {
  position: absolute;
  top: 50%;
  left: 50%;
}

.t-slds__arrow-withbg .t-slds__arrow_body-left {
  -webkit-transform: translate(-55%, -50%) rotate(180deg);
  -moz-transform: translate(-55%, -50%) rotate(180deg);
  -o-transform: translate(-55%, -50%) rotate(180deg);
  transform: translate(-55%, -50%) rotate(180deg);
}

.t-slds__arrow-withbg .t-slds__arrow_body-right {
  -webkit-transform: translate(-45%, -50%);
  -moz-transform: translate(-45%, -50%);
  -o-transform: translate(-45%, -50%);
  transform: translate(-45%, -50%);
}

.t-slds__arrow_body-left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

#allrecords ul.t-slds__items-wrapper {
  margin: 0 !important;
  padding: 0 !important;
}

#allrecords ul.t-slds__bullet_wrapper {
  margin: 0 !important;
  padding-left: 0 !important;
}

ul.t-slds__bullet_wrapper,
.t-slds__bullet_wrapper {
  text-align: center;
  position: relative;
  z-index: 15;
  padding: 16px 0 0;
  width: 100%;
  font-size: 0;
  list-style: none;
}

.t-slds__container .t-slds__bullet_wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.t-slds__container ul.t-slds__bullet_wrapper,
.t-slds__container .t-slds__bullet_wrapper {
  padding-bottom: 16px;
}

.t-slds__bullet {
  display: inline-block;
  padding: 10px 5px;
  cursor: pointer;
}

.t-slds__bullet_body {
  background-color: #eee;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  -webkit-transition: background-color ease-in-out 0.2s;
  -moz-transition: background-color ease-in-out 0.2s;
  -o-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
  box-sizing: border-box;
  border: none;
  padding: 0;
  cursor: pointer;
}

.t-slds__caption__container {
  position: relative;
  margin-top: 16px;
}

.t-slds__caption_wrapper {
  border-top: 1px solid #eee;
  padding: 14px 0;
}

.t-slds__caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
}

.t-slds__caption-active {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  opacity: 1;
}

.t-slds__descr {
  margin-top: 5px;
  color: #777;
}

@media screen and (max-width: 960px) {
  .t-width .t-slds__wrapper {
    padding: 0 20px;
  }

  .t-col_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0;
  }

  .t-width_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 20px;
  }

  .t-slds__arrow-left {
    left: 20px;
  }

  .t-slds__arrow-right {
    right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .t-slds__arrow-left {
    left: 5px;
  }

  .t-slds__arrow-right {
    right: 5px;
  }

  .t-store__relevants__container .t-slds__arrow-withbg {
    width: 40px !important;
    height: 40px !important;
  }

  .t-slds__arrow-withbg {
    width: 24px !important;
    height: 24px !important;
  }

  .t-slds__arrow_body {
    width: 10px !important;
  }

  .t-slds__arrow-withbg .t-slds__arrow_body {
    width: 6px !important;
  }

  .t-col .t-slds__witharrows .t-slds__wrapper {
    padding: 0 15px;
  }

  .t-width .t-slds__witharrows .t-slds__wrapper {
    padding: 0 35px;
  }

  .t-col_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 15px;
  }

  .t-width_12 .t-slds__witharrows .t-slds__wrapper {
    padding: 0 35px;
  }
}

.t-slds__play {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 2;
}

.t-slds__play_icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 70px;
  height: 70px;
  margin: 0 auto;
  -webkit-transition: all ease-in-out 0.2s;
  -moz-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}

.t-slds__play:hover .t-slds__play_icon {
  -moz-transform: translateY(-50%) scale(1.15);
  -ms-transform: translateY(-50%) scale(1.15);
  -webkit-transform: translateY(-50%) scale(1.15);
  -o-transform: translateY(-50%) scale(1.15);
  transform: translateY(-50%) scale(1.15);
}

.t-slds__frame {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.t-slds__thumbsbullet-wrapper {
  font-size: 0;
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbsbullet-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbs_gallery:before,
.t-slds__thumbsbullets-with-gallery .t-slds__thumbs_gallery:after {
  opacity: 1;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbs_gallery:before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbs_gallery:after {
  content: "+ " attr(data-gallery-length);
  letter-spacing: -4px;
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
  height: 30px;
  color: white;
  text-align: center;
  margin: auto;
  font-family: "Roboto", Arial, sans-serif;
}

.t-slds__thumbsbullets-with-gallery.t-slds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}

.t-slds__thumbsbullets-with-gallery .t-slds__main {
  width: calc(100% - 70px);
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbsbullet.t-slds__bullet {
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
}

.t-slds__thumbsbullets-with-gallery
  .t-slds__thumbsbullet.t-slds__bullet:last-child {
  margin-bottom: 0;
}

.t-slds__thumbsbullets-with-gallery .t-slds__thumbsbullet-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 60px;
  margin-right: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media screen and (max-width: 640px) {
  .t-slds__thumbsbullets-with-gallery .t-slds__main {
    width: 100%;
  }

  .t-slds__thumbsbullets-with-gallery .t-slds__thumbsbullet-wrapper {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-right: 0;
    margin-top: 10px;
  }

  .t-slds__thumbsbullets-with-gallery .t-slds__thumbsbullet.t-slds__bullet {
    width: 60px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  .t-slds__thumbsbullets-with-gallery.t-slds {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .t-slds__thumbsbullets-with-gallery .t-slds__thumbs_gallery:after {
    font-size: 32px;
  }
}

.t-slds__thumbsbullet.t-slds__bullet {
  padding: 0;
  width: 60px;
  position: relative;
  -webkit-transition: opacity ease-in-out 0.3s;
  -moz-transition: opacity ease-in-out 0.3s;
  -o-transition: opacity ease-in-out 0.3s;
  transition: opacity ease-in-out 0.3s;
  margin-right: 5px;
  margin-top: 4px;
}

.t-slds__thumbsbullet-border {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 2px solid transparent;
  -webkit-transition: border-color ease-in-out 0.3s;
  -moz-transition: border-color ease-in-out 0.3s;
  -o-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}

.t-slds__thumbsbullet.t-slds__bullet_active .t-slds__thumbsbullet-border {
  border-color: rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 640px) {
  .t-slds__thumbsbullet.t-slds__bullet {
    width: 40px;
    margin-right: 4px;
    margin-top: 3px;
  }
}

@media screen and (max-width: 480px) {
  .t-slds__arrow {
    outline-offset: 3px;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
img,
b,
u,
i,
center,
table,
tr,
th,
td,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

sup {
  line-height: 0;
  font-size: smaller;
  vertical-align: super;
}

sub {
  line-height: 0;
  font-size: smaller;
  vertical-align: sub;
}

.t-clear {
  clear: both;
}

.t-row {
  margin: 0;
}

.t-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
  padding: 0;
}

.t-container.flexx {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.t-container_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.t-container_100 {
  width: 100%;
  padding: 0;
}

.t-container_10 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1000px;
  padding: 0;
}

.t-container_8 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
  padding: 0;
}

.t-container:before,
.t-container:after,
.t-container_10:before,
.t-container_10:after,
.t-container_8:before,
.t-container_8:after,
.t-container_100:before,
.t-container_100:after {
  display: table;
  content: " ";
  width: 0;
}

.t-container:after,
.t-container_10:after,
.t-container_8:after,
.t-container_100:after {
  clear: both;
}

.t-col {
  display: inline;
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.t-col.flexx {
  align-items: stretch;
  height: inherit;
  margin-top: auto;
  margin-bottom: auto;
}

.t-col_flex {
  align-items: stretch;
  height: inherit;
  margin-top: auto;
  margin-bottom: auto;
}

.t-col_1 {
  max-width: 60px;
}

.t-col_2 {
  max-width: 160px;
}

.t-col_3 {
  max-width: 260px;
}

.t-col_4 {
  max-width: 360px;
}

.t-col_5 {
  max-width: 460px;
}

.t-col_6 {
  max-width: 560px;
}

.t-col_7 {
  max-width: 660px;
}

.t-col_8 {
  max-width: 760px;
}

.t-col_9 {
  max-width: 860px;
}

.t-col_10 {
  max-width: 960px;
}

.t-col_11 {
  max-width: 1060px;
}

.t-col_12 {
  max-width: 1160px;
}

.t-col_100 {
  max-width: 100vw;
}

.t-prefix_1 {
  padding-left: 100px;
}

.t-prefix_2 {
  padding-left: 200px;
}

.t-prefix_3 {
  padding-left: 300px;
}

.t-prefix_4 {
  padding-left: 400px;
}

.t-prefix_5 {
  padding-left: 500px;
}

.t-prefix_6 {
  padding-left: 600px;
}

.t-prefix_7 {
  padding-left: 700px;
}

.t-prefix_8 {
  padding-left: 800px;
}

.t-prefix_9 {
  padding-left: 900px;
}

.t-prefix_10 {
  padding-left: 1000px;
}

.t-prefix_11 {
  padding-left: 1100px;
}

.t-prefix_12 {
  padding-left: 1200px;
}

.t-width {
  width: 100%;
}

.t-width_1 {
  max-width: 60px;
}

.t-width_2 {
  max-width: 160px;
}

.t-width_3 {
  max-width: 260px;
}

.t-width_4 {
  max-width: 360px;
}

.t-width_5 {
  max-width: 460px;
}

.t-width_6 {
  max-width: 560px;
}

.t-width_7 {
  max-width: 660px;
}

.t-width_8 {
  max-width: 760px;
}

.t-width_9 {
  max-width: 860px;
}

.t-width_10 {
  max-width: 960px;
}

.t-width_11 {
  max-width: 1060px;
}

.t-width_12 {
  max-width: 1160px;
}

.t-width_100 {
  max-width: 100%;
}

.t-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}

.t-cell_25 {
  width: 25vw;
}

.t-cell_33 {
  width: 33.3333333vw;
}

.t-cell_50 {
  width: 50vw;
}

.t-cell_100 {
  width: 100vw;
}

#app {
  display: inline-block !important;
}

@media screen and (max-width: 1200px) {
  .t-container {
    max-width: 960px;
    padding: 0;
  }

  .t-container_10 {
    max-width: 780px;
  }

  .t-container_8 {
    max-width: 640px;
  }

  .t-col {
    display: inline;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  .t-col_1 {
    max-width: 60px;
  }

  .t-col_2 {
    max-width: 140px;
  }

  .t-col_3 {
    max-width: 220px;
  }

  .t-col_4 {
    max-width: 300px;
  }

  .t-col_5 {
    max-width: 380px;
  }

  .t-col_6 {
    max-width: 460px;
  }

  .t-col_7 {
    max-width: 540px;
  }

  .t-col_8 {
    max-width: 620px;
  }

  .t-col_9 {
    max-width: 700px;
  }

  .t-col_10 {
    max-width: 780px;
  }

  .t-col_11 {
    max-width: 860px;
  }

  .t-col_12 {
    max-width: 940px;
  }

  .t-prefix_1 {
    padding-left: 80px;
  }

  .t-prefix_2 {
    padding-left: 160px;
  }

  .t-prefix_3 {
    padding-left: 240px;
  }

  .t-prefix_4 {
    padding-left: 320px;
  }

  .t-prefix_5 {
    padding-left: 400px;
  }

  .t-prefix_6 {
    padding-left: 480px;
  }

  .t-prefix_7 {
    padding-left: 560px;
  }

  .t-prefix_8 {
    padding-left: 640px;
  }

  .t-prefix_9 {
    padding-left: 720px;
  }

  .t-prefix_10 {
    padding-left: 800px;
  }

  .t-prefix_11 {
    padding-left: 880px;
  }

  .t-prefix_12 {
    padding-left: 960px;
  }

  .t-width_1 {
    max-width: 60px;
  }

  .t-width_2 {
    max-width: 140px;
  }

  .t-width_3 {
    max-width: 220px;
  }

  .t-width_4 {
    max-width: 300px;
  }

  .t-width_5 {
    max-width: 380px;
  }

  .t-width_6 {
    max-width: 460px;
  }

  .t-width_7 {
    max-width: 540px;
  }

  .t-width_8 {
    max-width: 620px;
  }

  .t-width_9 {
    max-width: 700px;
  }

  .t-width_10 {
    max-width: 780px;
  }

  .t-width_11 {
    max-width: 860px;
  }

  .t-width_12 {
    max-width: 940px;
  }

  .t-width_100 {
    max-width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .t-container {
    max-width: 640px;
  }

  .t-container.flexx {
    display: block;
  }

  .t-container_flex {
    display: block;
  }

  .t-col {
    display: block;
    float: none;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .t-col_1,
  .t-col_2,
  .t-col_3,
  .t-col_4,
  .t-col_5,
  .t-col_6,
  .t-col_7,
  .t-col_8,
  .t-col_9,
  .t-col_10,
  .t-col_11,
  .t-col_12 {
    width: 100%;
    max-width: 100%;
  }

  .t-prefix_1,
  .t-prefix_2,
  .t-prefix_3,
  .t-prefix_4,
  .t-prefix_5,
  .t-prefix_6,
  .t-prefix_7,
  .t-prefix_8,
  .t-prefix_9,
  .t-prefix_10,
  .t-prefix_11,
  .t-prefix_12 {
    padding-left: none;
  }
}
</style>
